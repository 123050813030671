<template>
  <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4">
    <div class="dataTables_length" id="DataTables_Table_0_length">
      <label>
        {{ $t("show") }}
        <select
          name="DataTables_Table_0_length"
          aria-controls="DataTables_Table_0"
          class="form-control form-control-sm"
          v-model="entries"
          @input="search()"
        >
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
          <option :value="200">200</option>
          <option :value="500">500</option>
        </select>
        {{ $t("rows") }}
      </label>
    </div>
    <div id="DataTables_Table_0_filter" class="dataTables_filter">
      <div
        v-if="this.excel"
        class="btn btn-xs btn-primary my-1 mr-1"
        @click="downloadExcel()"
      >
        <i class="fa fa-ferfesh">{{ $t("download_excel") }}</i>
      </div>
      <div class="btn btn-xs btn-primary my-1" @click="getData('?page=' + 1)">
        <i class="fa fa-ferfesh">{{ $t("refresh_table") }}</i>
      </div>
    </div>
    <div
      class="dataTables_info"
      id="DataTables_Table_0_info"
      role="status"
      aria-live="polite"
    >
      Showing {{ entries * (current_page - 1) + 1 }} to {{ to }} of
      {{ total }} entries
    </div>
    <div id="scroll1" class="table-responsive">
      <div style="height: 1px"></div>
    </div>
    <div id="scroll2" class="table-responsive">
      <table
        class="
          table table-striped table-bordered table-hover
          dataTables-example
          dataTable
        "
        id="DataTables_Table_0"
        aria-describedby="DataTables_Table_0_info"
        role="grid"
      >
        <thead v-if="theadRow">
          <tr role="row">
            <th tabindex="0" rowspan="1" colspan="1">#</th>
            <th
              tabindex="0"
              rowspan="1"
              colspan="1"
              v-for="(column, i) in columns"
              :key="i"
            >
              <div v-if="filterable(column)" style="position: relative">
                <input
                  v-if="column.type === 'text'"
                  type="text"
                  class="form-control"
                  v-model="column.search"
                  @keyup="search(column)"
                  autocomplete="off"
                />
                <date-picker
                  class="NeoDataPicker"
                  v-model="column.search"
                  :config="optionsDate"
                  :name="column.key"
                  v-else-if="column.type === 'date'"
                  @dp-change="search(column)"
                  autocomplete="off"
                />
                <select
                  class="form-control"
                  :name="column.key"
                  v-validate="'required'"
                  v-model="column.search"
                  v-else-if="column.type === 'list'"
                  @change="search(column)"
                  autocomplete="off"
                >
                  <option
                    v-for="(item, i) in column.list"
                    :key="i"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </th>
          </tr>
        </thead>
        <thead>
          <tr role="row">
            <th tabindex="0" rowspan="1" colspan="1">Lp</th>
            <th
              :class="{
                sorting:
                  column.sortable &&
                  (column.sort == null || column.sort == undefined),
                sorting_asc: column.sortable && column.sort == 'asc',
                sorting_desc: column.sortable && column.sort == 'desc'
              }"
              @click="changeSorting(column)"
              tabindex="0"
              rowspan="1"
              colspan="1"
              aria-sort="ascending"
              v-for="(column, i) in columns"
              :key="i"
            >
              {{ column.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            role="row"
            v-for="(row, i) in table_list"
            :key="i"
            :style="rowStyle(row)"
          >
            <td>
              {{ entries * (current_page - 1) + i + 1 }}
            </td>
            <td
              v-for="(column, j) in columns"
              :key="i + '-' + j"
              :class="columnClass(column)"
            >
              <span
                @click="prepareFunctionToRun(row, column)"
                v-html="prepareDataToShow(row, column)"
              ></span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th rowspan="1" colspan="1">Lp</th>
            <th rowspan="1" colspan="1" v-for="(column, i) in columns" :key="i">
              {{ column.name }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      class="dataTables_paginate paging_simple_numbers"
      id="DataTables_Table_0_paginate"
    >
      <ul class="pagination">
        <li
          class="paginate_button page-item previous"
          :class="{ disabled: current_page === 1 }"
        >
          <a
            href="#"
            aria-controls="DataTables_Table_0"
            data-dt-idx="0"
            tabindex="0"
            class="page-link"
          >
            Previous
          </a>
        </li>
        <li
          class="paginate_button page-item"
          :class="{ active: current_page === 1 }"
          v-if="start >= 1"
        >
          <a
            href="#1"
            aria-controls="DataTables_Table_0"
            :data-dt-idx="1"
            tabindex="0"
            class="page-link"
            @click.prevent="getData('?page=' + 1)"
          >
            1
          </a>
        </li>
        <li class="paginate_button page-item" v-if="start > 1">
          <a
            href="#"
            aria-controls="DataTables_Table_0"
            :data-dt-idx="1"
            tabindex="0"
            class="page-link"
          >
            ...
          </a>
        </li>
        <li
          class="paginate_button page-item"
          :class="{ active: current_page === start + page_number }"
          v-for="(page_number, i) in end - start"
          :key="i"
        >
          <a
            :href="'#' + (start + page_number)"
            aria-controls="DataTables_Table_0"
            :data-dt-idx="start + page_number"
            tabindex="0"
            class="page-link"
            @click.prevent="getData('?page=' + (start + page_number))"
          >
            {{ start + page_number }}
          </a>
        </li>
        <li class="paginate_button page-item" v-if="end < last_page">
          <a
            href="#"
            aria-controls="DataTables_Table_0"
            :data-dt-idx="1"
            tabindex="0"
            class="page-link"
          >
            ...
          </a>
        </li>
        <li
          class="paginate_button page-item"
          :class="{ active: current_page === last_page }"
          v-if="end < last_page"
        >
          <a
            :href="'#' + last_page"
            aria-controls="DataTables_Table_0"
            :data-dt-idx="last_page"
            tabindex="0"
            class="page-link"
            @click.prevent="getData('?page=' + last_page)"
          >
            {{ last_page }}
          </a>
        </li>
        <li
          class="paginate_button page-item next"
          :class="{ disabled: current_page === last_page }"
        >
          <a
            href="#"
            aria-controls="DataTables_Table_0"
            data-dt-idx="4"
            tabindex="0"
            class="page-link"
            @click.prevent="getData('?page=' + (current_page + 1))"
          >
            Next
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  data() {
    return {
      valueFromExcel: [],
      entries: 10,
      current_page: 1,
      last_page: 1,
      total: 0,
      table_list: [],
      to: 0,
      pagination_link_count: 7,
      timeReloadSearchData: 1500
    };
  },
  props: {
    excel: {
      type: Boolean,
      default: false
    },
    dateStart: {
      type: String,
      default: null
    },
    dateEnd: {
      type: String,
      default: null
    },
    url: {
      type: String,
      required: true
    },
    columns_list: {
      type: Array,
      required: true
    },
    addData: {
      required: false,
      default: null
    },
    rowStyle: {
      type: Function,
      default: () => ""
    },
    empikPost: {
      type: Boolean,
      default: false
    },
    giftCardUsed: {
      type: Boolean,
      default: false
    },
    theadRow: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    url: function() {
      this.getData();
    },
    dateStart: function() {
      this.getData();
    },
    dateEnd: function() {
      this.getData();
    },
    addData: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  computed: {
    columns() {
      return this.columns_list.filter(
        column => column.show === undefined || column.show === true
      );
    },
    start() {
      return this.current_page - this.pagination_link_count > 0
        ? this.current_page - this.pagination_link_count
        : 1;
    },
    end() {
      return this.current_page + this.pagination_link_count < this.last_page
        ? this.current_page + this.pagination_link_count
        : this.last_page;
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    document.querySelector("#scroll1").addEventListener("scroll", this.scroll);
    document.querySelector("#scroll2").addEventListener("scroll", this.scroll);
  },
  updated() {
    document.querySelector("#scroll1 div").style.width = `${
      document.querySelector("#scroll2 table").clientWidth
    }px`;
  },
  beforeDestroy() {
    if (document.querySelector("#scroll1")) {
      document
        .querySelector("#scroll1")
        .removeEventListener("scroll", this.scroll);
    }
    if (document.querySelector("#scroll2")) {
      document
        .querySelector("#scroll2")
        .removeEventListener("scroll", this.scroll);
    }
  },
  methods: {
    columnClass(column) {
      let classText = column.class !== undefined ? column.class : "";
      if (column.filter !== undefined) {
        if (column.filter == "boolean") {
          classText += "text-center";
        }
        if (column.filter == "number") {
          classText += "text-right";
        }
      }
      return classText;
    },
    scroll(e) {
      if (e.target.id === "scroll1") {
        document.querySelector("#scroll2").scrollLeft = e.target.scrollLeft;
      } else {
        document.querySelector("#scroll1").scrollLeft = e.target.scrollLeft;
      }
    },
    changeSorting(column) {
      if (column.sortable) {
        this.columns.map(column2 => {
          if (column2 !== column) {
            column2.sort = null;
          }
          return column2;
        });
        if (column.sort === null || column.sort === undefined) {
          column.sort = "asc";
        } else if (column.sort === "asc") {
          column.sort = "desc";
        } else {
          column.sort = null;
        }
        this.$forceUpdate();
        this.getData();
      }
    },
    search(column = true) {
      this.$forceUpdate();
      this.$nextTick(() => {
        if (!column.search) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.getData();
          }, this.timeReloadSearchData);
          return 0;
        }
        if (column.search.length > 1) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.getData();
          }, this.timeReloadSearchData);
        }
      });
    },
    filterable(column) {
      return column.filterable == undefined || column.filterable == true
        ? true
        : false;
    },
    getData(page = "") {
      this.$emit("loading", true);
      const filter = {
        entries: this.entries,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        addData: this.addData,
        sort: {}
      };
      this.columns.map(column => {
        if (column.search !== undefined && column.search !== "") {
          filter[column.key] = column.search;
        }
        if (column.sort !== undefined && column.sort !== null) {
          filter.sort[column.key] = column.sort;
        }
        return filter;
      });
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$http
          .get(this.url + page, { params: filter })
          .then(resp => {
            if (this.empikPost) {
              this.table_list = resp.data.offers;
              this.current_page = 1;
              this.last_page = Math.ceil(resp.data.total_count / this.entries);
              this.total = resp.data.total_count;
              this.to = 9;
              this.$emit("list", this.table_list.length);
              this.$emit("loading", false);
            } else {
              this.table_list = resp.data.table.data;
              this.current_page =
                resp.data.table.current_page !== undefined
                  ? resp.data.table.current_page
                  : 1;
              this.last_page =
                resp.data.table.last_page !== undefined
                  ? resp.data.table.last_page
                  : 1;
              this.total =
                resp.data.table.total !== undefined ? resp.data.table.total : 1;
              this.to =
                resp.data.table.to !== undefined ? resp.data.table.to : 1;
              this.$emit("list", this.table_list.length);
              this.$emit("loading", false);
            }
          })
          .catch(err => {
            this.$toastr.error(err);
            this.$emit("loading", false);
          });
      });
    },
    downloadExcel() {
      this.$emit("loading", true);
      const filter = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        addData: this.addData,
        excel: true,
        sort: {}
      };
      this.columns.map(column => {
        filter[column.key] = column.search || "";
        if (column.sort !== undefined && column.sort !== null) {
          filter.sort[column.key] = column.sort;
        }
        return filter;
      });
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$http
          .get(this.url, filter)
          .then(resp => {
            this.valueFromExcel = {
              value: resp.data.table,
              table: this.columns_list
            };
            this.$http({
              url: "excel/download",
              method: "POST",
              data: this.valueFromExcel,
              responseType: "blob"
            })
              .then(response => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "excel.xlsx");
                document.body.appendChild(link);
                link.click();
              })
              .catch(err => {
                this.$toastr.error(err);
                this.$emit("loading", false);
              })
              .finally(() => {
                this.$toastr.success("Pobrany excel");
                this.$emit("loading", false);
              });
          })
          .catch(err => {
            this.$toastr.error(err);
            this.$emit("loading", false);
          });
      });
    },
    prepareDataToShow(row, column) {
      if (column.filter) {
        switch (column.filter) {
          case "boolean":
            return row[column.key] !== 0 || row[column.key] === "on"
              ? '<i class="fa fa-check text-info"></i>'
              : '<i class="fa fa-times text-danger"></i>';
          case "image":
            return `<img src="${row[column.key]}" width="${column.width}" />`;
          case "btn":
            return column.btn(row[column.key], row, column.key);
          case "function":
            return column.col_function(row[column.key], row, column.key);
          case "number":
            return row[column.key].toString().replace(".", ",");
        }
      }
      return row[column.key];
    },
    prepareFunctionToRun(row, column) {
      if (column.touch) {
        column.function(row[column.key], row, column.key);
        this.$forceUpdate();
        if (column.dont_refresh === undefined || !column.dont_refresh) {
          this.getData(`?page= ${this.current_page}`);
        }
      }
    }
  }
};
</script>
